// Colors
$black           : #000000;
$white           : #ffffff;
$amber_yellow    : #FFC400;
$tuna            : #3C3E49;
$athensgray      : #ECEDF0;
$mischka         : #DFE0E6;
$pattensblue     : #E1F6FF;
$dodgerblue      : #40C4FF;


//Link
$imageurl        :  "../../images";
$fonturl         :  "../../fonts";

//Font
$regular_font    : 'Rubik-Regular';
$medium_font     : 'Rubik-Medium';



//Grid
$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px
) !default;


@include font-face($regular_font, '#{$fonturl}/Rubik-Regular', 'normal', 'normal');
@include font-face($medium_font, '#{$fonturl}/Rubik-Medium', 'normal', 'normal');
  

