.error_page {
    .error_sec {
        .inner_view {
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 448px;
            margin: 0 auto;
            text-align: left;
            > img {
                margin-bottom: 64px;
            }
            h2 {
                font-size: 32px;
                font-family: $medium_font;
                color: $tuna;
                letter-spacing: -0.02em;
                margin-bottom: 16px;
            }
            p {
                font-size: 18px;
                line-height: 32px;
                margin-bottom: 0;
                color: $tuna;
            }
        }
    }
}
