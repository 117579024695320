.home_page {
  .step_view_sec {
    position: relative;
    min-height: 850px;
    .inner_view {
      width: 800px;
      min-height: 500px;
      margin: 0 auto;
      position: relative;
      padding: 50px 0;

      .progress_bar {
        margin: 0 0 35px 0;

        .progress-bar {
          background-color: #1DB954;
        }
      }

      .steps {
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.08));
        background-color: $white;
        min-height: 500px;
        border-radius: 8px;
        overflow: hidden;

        .btns_view {
          width: 100%;
          height: 76px;
          border-top: 1px solid #ECEDF0;
          .btn {
            padding: 8px 20px;
            height: 36px;
            float: right;
            margin-top: 20px;
            // Font
            font-family: "Rubik-Medium", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            // Color
            color: #000000;
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 3px;
            &.previous {
              margin-right: 20px;
            }
            &.next {
              margin-right: 30px;
            }
            &.launch {
              border: 0px;
              background: #1DB954;
              color: #FFFFFF;
            }
          }
          .dots {
            display: inline-block;
            margin-top: 34px;
            margin-left: 30px;
            width: 60px;
            height: 8px;
            font-size: 0;
            li {
              width: 8px;
              height: 8px;
              background-color: $mischka;
              border-radius: 4px;
              cursor: pointer;
              display: inline-flex;
              &:not(:last-child) {
                margin-right: 8px;
              }
              &.active {
                background-color: $tuna;
              }
            }
          }
        }

        &.step_div {
          .no-gutters > .col_7,
          .no-gutters > .col_5,
          .no-gutters > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
          }
          .col_7,
          .col_5 {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
          }
          .col_7 {
            @include media-breakpoint-up(md) {
              flex: 0 0 53%;
              max-width: 53%;
            }
          }
          .col_5 {
            @include media-breakpoint-up(md) {
              flex: 0 0 47%;
              max-width: 47%;
            }
          }
          .left_view {
            padding: 48px;
            height: 424px;

            &__first-page {
              height: 800px;
            }

            .top_section {
              margin-bottom: 35px;
            }

            h1, .h1 {
              font-family: $medium_font;
              font-style: normal;
              font-weight: 500;
              font-size: 36px;
              line-height: 40px;
              margin-bottom: 48px;
            }
            h2, .h2 {
              font-size: 16px;
              font-family: $medium_font;
              margin-bottom: 30px;
              line-height: 24px;
            }
            p {
              font-size: 14px;
              font-family: $medium_font;
              margin-bottom: 20px;
              &.large {
                font-size: 16px;
              }
              &.regular {
                font-family: $regular_font;
              }
            }
            ul {
              margin-left: -15px;
              padding: 0 0px 0 15px;
              list-style: none;
              li {
                font-family: $regular_font;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                color: #3C3E49;
                &:not(:last-child) {
                  margin-bottom: 18px;
                }
                &::before {
                  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                  color: #D0D2DA; /* Change the color */
                  font-weight: bold; /* If you want it to be bold */
                  display: inline-block; /* Needed to add space between the bullet and the text */
                  width: 1em; /* Also needed for space (tweak if needed) */
                  margin-left: -1em; /* Also needed for space (tweak if needed) */
                }
              }
            }
          }
          .right_view_1 {
            height: 100%;
            position: relative;
            z-index: 0;
            background: url("#{$imageurl}/S1.png")
              no-repeat left top;
            &:after,
            &:before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            background-size: cover;
          }
          .right_view_2 {
            height: 100%;
            position: relative;
            z-index: 0;
            background: url("#{$imageurl}/S2.png")
              no-repeat left top;
            &:after,
            &:before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            background-size: contain;
          }
          .right_view_3 {
            height: 100%;
            position: relative;
            z-index: 0;
            background: url("#{$imageurl}/S3.png")
              no-repeat left top;
            &:after,
            &:before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            background-size: contain;
          }
          .right_view_4 {
            height: 100%;
            position: relative;
            z-index: 0;
            background: url("#{$imageurl}/S1.png")
              no-repeat left top;
            &:after,
            &:before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            background-size: contain;
          }
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    // transform: scale(0.95);
    opacity: 0.3;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }

  70% {
    // transform: scale(1);
    opacity: 0.6;
  }
  85% {
    opacity: 0.4;
  }

  100% {
    // transform: scale(0.95);
    opacity: 0.3;
  }
}
