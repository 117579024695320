body,
html{
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: $regular_font;
    color: $black;
    background-color: #F8F9FA;
}

a {
    @include transition (linear 0.3s);
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn{
    padding: 13px 23px;
    cursor: pointer;
    border:1px solid transparent;
    font-size:14px;
    border-radius: 4px;
    line-height: normal;

    &.primary_btn{
        color: $white;
        background-color: $black;
        &:hover,
        &:focus,
        &:active{
            color: $white;
            background-color: $black;
        }
    }

    &:hover,
    &:focus{
        @include no-outline();
        @include box-shadow(none);
    }
}